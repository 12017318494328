@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.animate-slide-up {
  animation: slide-up 0.3s ease-out;
}

.animate-slide-down {
  animation: slide-down 0.3s ease-out;
}

/* Popup.css */
/* Define vars we'll be using */
:root {
  --brand-success: #5cb85c;
  --brand-danger: #d9534f;
  --loader-size: 7em;
  --check-height: calc(var(--loader-size) / 2);
  --check-width: calc(var(--check-height) / 2);
  --check-left: calc((var(--loader-size) / 6) + (var(--loader-size) / 12));
  --check-thickness: 3px;
  --check-color: var(--brand-success);
  --cross-color: var(--brand-danger);
}

.popup-container {
  text-align: center;
}

.circle-loader {
  margin-bottom: calc(var(--loader-size) / 2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--check-color);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
}

.load-complete {
  animation: none;
  border-color: var(--check-color);
  transition: border 500ms ease-out;
}

.load-failed {
  animation: none;
  border-color: var(--cross-color);
  transition: border 500ms ease-out;
}

.checkmark,
.crossmark {
  display: none;
}

.checkmark.draw:after,
.crossmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark, crossmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark:after,
.crossmark:after {
  opacity: 1;
  height: var(--check-height);
  width: var(--check-width);
  transform-origin: left top;
  content: '';
  left: var(--check-left);
  top: var(--check-height);
  position: absolute;
}

.checkmark.draw:after {
  border-right: var(--check-thickness) solid var(--check-color);
  border-top: var(--check-thickness) solid var(--check-color);
  display: block;
}

.crossmark.draw:after {
  border-right: var(--check-thickness) solid var(--cross-color);
  border-top: var(--check-thickness) solid var(--cross-color);
  display: block;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: var(--check-width);
    opacity: 1;
  }
  40% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
  100% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
}

@keyframes crossmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: var(--check-width);
    opacity: 1;
  }
  40% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
  100% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
}

.event-text {
  transition: color 0.3s ease;
}
